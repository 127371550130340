.landing-page {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.img1 {
    width: 101%;
    height: 100%;
    margin-bottom: -11px;
}

.button1 {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    gap: 20px;
    top: 580px;
    left: 407px;
    width: 273px;
    height: 90px;
    font: normal normal bold 35px Almarai;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #FFFFFF4A;
    border-radius: 56px;
    opacity: 1;
    cursor: pointer;
}

.img8-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("./components/img/Mask\ Group\ 11.jpg");
    background-size: cover;
    background-position: center;
}

.img8-container::after {
    content: "";
    display: block;
    width: 100%;
    padding-top: 320%;
    /* This helps maintain the aspect ratio */
}

.img8-container>* {
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 100%;
}

.img8-container .swiper-container {
    width: 100%;
    height: 90%;
}

.img8-container .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 32%;
    background: transparent;
    /* Add scale property to make the middle slide pop out */
    transform: scale(1);
    transition: transform 0.3s ease;
    /* Set z-index to ensure the middle slide is on top */
    z-index: 1;
    top: 66%;
}

.img8-container .swiper-slide.swiper-slide-active {
    /* Increase the scale value for the active slide to make it pop out */
    transform: scale(1.05);
    z-index: 2; /* Increase the z-index for the active slide */
}


.box {
    position: relative;
    width: 100%;
    padding: 20px;
    padding-top: 20%;
}

.card {
    width: 100%;
    height: 100%;
}

.img7 {
    width: 100%;
    position: relative;
    background-image: url("./components/img/Mask\ Group\ 11.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
}

.img7::after {
    content: "";
    display: block;
    width: 100%;
    padding-top: 66.25%;
    /* This helps maintain the aspect ratio */
}

.img7>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.img6 {
    display: flex;
    position: relative;
    justify-content: center;
    top: 170px;
    left: 76px;
    width: 100%;
    height: 575px;
    /* UI Properties */
    background: #BBBBBB 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 160px;
    opacity: 1;
}

.title {
    width: auto;
    height: 114px;
    text-align: right;
    font: normal normal bold 53px/114px Almarai;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    position: absolute;
}

.line {
    position: relative;
    width: 45%;
    height: 0px;
    /* UI Properties */
    background-color: #BBBBBB;
    box-shadow: 0px 3px 6px #00000029;
    border: 6px solid #19c910;
    opacity: 1;
}

.text-input {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    width: 72%;
    gap: 4%;
}

.textarea {
    position: relative;
    margin-top: 52%;

    width: 68%;
    height: 24%;
    /* UI Properties */
    border: 7px solid #F4FFC5;
    background-color: transparent;
    border-radius: 22px;
    opacity: 1;
    text-align: right;
    font-family: Almarai;
    font-size: 25px;
    letter-spacing: 0px;
    color: #001B0B;
    padding-right: 10px;
    padding-top: 5px;
    outline: none;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 65%;
    margin-right: 370px;
    width: 123px;
    height: 42px;
    font: normal normal bold 18px Almarai;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 55px;
    opacity: 1;
    cursor: pointer;
}

.whatsapp-container {
    position: fixed;
    bottom: 0px;
    right: -35px;
    z-index: 9999;
    /* Ensure it's above other content */
}

.whatsapp {
    width: 170px;
    cursor: pointer;
}

@media (max-width: 1400px) {
    .button1 {
        gap: 18px;
        top: 530px;
        left: 337px;
        width: 263px;
        height: 80px;
        font-size: 30px;
    }

    .img8-container .swiper-container {
        padding-top: 150px;
    }

    .img8-container .swiper-slide {
        width: 30%;
    }

    .img6 {
        left: 20px;
        margin-top: -70px;
        height: 595px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 40%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 43px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 16%;
        gap: 5%;
    }

    .textarea {
        position: absolute;
        margin-top: 55%;
        width: 68%;
        height: 26%;
        /* UI Properties */
        border: 7px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 25px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 72%;
        margin-right: 44%;
        width: 123px;
        height: 42px;
        font: normal normal bold 20px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -35px;
    }

    .whatsapp {
        width: 160px;
        cursor: pointer;
    }
}

@media (max-width: 1280px) {

    .img6 {
        left: 20px;
        margin-top: -100px;
        height: 565px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }
    
    .textarea {
        position: absolute;
        margin-top: 58%;
        width: 68%;
        height: 26%;
        /* UI Properties */
        border: 7px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 25px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 75%;
        margin-right: 44%;
        width: 123px;
        height: 42px;
        font: normal normal bold 20px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }
}

@media (max-width: 1220px) {
    .button1 {
        gap: 16px;
        top: 440px;
        left: 307px;
        width: 203px;
        height: 60px;
        font-size: 25px;
    }

    .img8-container .swiper-container {
        padding-top: 150px;
    }

    .img8-container .swiper-slide {
        width: 30%;
    }

    .img6 {
        left: 20px;
        margin-top: -100px;
        height: 495px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 40%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 33px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 16%;
        gap: 5%;
    }

    .textarea {
        position: absolute;
        margin-top: 60%;
        width: 68%;
        height: 26%;
        /* UI Properties */
        border: 7px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 25px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 77%;
        margin-right: 44%;
        width: 123px;
        height: 42px;
        font: normal normal bold 20px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -33px;
    }

    .whatsapp {
        width: 140px;
        cursor: pointer;
    }
}

@media (max-width: 1050px) {
    .button1 {
        gap: 14px;
        top: 400px;
        left: 257px;
        width: 173px;
        height: 50px;
        font-size: 20px;
    }

    .img8-container .swiper-container {
        padding-top: 140px;
    }

    .img8-container .swiper-slide {
        width: 30%;
    }

    .img6 {
        left: 20px;
        margin-top: -140px;
        height: 455px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 35%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 23px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 16%;
        gap: 5%;
    }

    .textarea {
        position: absolute;
        margin-top: 55%;
        width: 68%;
        height: 28%;
        /* UI Properties */
        border: 5px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 10px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 74%;
        margin-right: 47%;
        width: 80px;
        height: 35px;
        font: normal normal bold 14px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -32px;
    }

    .whatsapp {
        width: 130px;
        cursor: pointer;
    }
}

@media (max-width: 900px) {
    .button1 {
        gap: 12px;
        top: 340px;
        left: 237px;
        width: 143px;
        height: 40px;
        font-size: 15px;
    }

    .img8-container .swiper-container {
        padding-top: 100px;
    }

    .img8-container .swiper-slide {
        width: 30%;
    }

    .img6 {
        left: 20px;
        margin-top: -140px;
        height: 425px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 35%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 23px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 20%;
        gap: 5%;
    }

    .textarea {
        position: absolute;
        margin-top: 55%;
        width: 68%;
        height: 28%;
        /* UI Properties */
        border: 5px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 10px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 76%;
        margin-right: 47%;
        width: 80px;
        height: 35px;
        font: normal normal bold 14px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -34px;
    }

    .whatsapp {
        width: 120px;
        cursor: pointer;
    }
}

@media (max-width: 780px) {
    .button1 {
        gap: 10px;
        top: 280px;
        left: 200px;
        width: 103px;
        height: 30px;
        font-size: 10px;
    }

     .img8-container .swiper-container {
        padding-top: 100px;
    }

    .img8-container .swiper-slide {
        width: 30%;
    }

    .img6 {
        left: 20px;
        margin-top: -140px;
        height: 305px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 40%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 23px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 20%;
        gap: 5%;
    }

    .textarea {
        position: absolute;
        margin-top: 52%;
        width: 68%;
        height: 28%;
        /* UI Properties */
        border: 5px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 10px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 71%;
        margin-right: 50%;
        width: 50px;
        height: 15px;
        font: normal normal bold 9px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -27px;
    }

    .whatsapp {
        width: 100px;
        cursor: pointer;
    }
}

@media (max-width: 680px) {
    .button1 {
        gap: 8px;
        top: 250px;
        left: 170px;
        width: 90px;
        height: 30px;
        font-size: 10px;
    }

    .img8-container .swiper-container {
        padding-top: 70px;
    }

    .img8-container .swiper-slide {
        width: 30%;
    }

    .img6 {
        left: 20px;
        margin-top: -140px;
        height: 280px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 40%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 23px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 23%;
        width: 76%;
    }

    .textarea {
        position: absolute;
        margin-top: 61%;
        width: 68%;
        height: 22%;
        /* UI Properties */
        border: 5px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 10px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 76%;
        margin-right: 48%;
        width: 50px;
        height: 15px;
        font: normal normal bold 9px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -26px;
    }

    .whatsapp {
        width: 90px;
        cursor: pointer;
    }
}

@media (max-width: 550px) {
    .button1 {
        gap: 8px;
        top: 200px;
        left: 140px;
        width: 90px;
        height: 30px;
        font-size: 10px;
    }

    .img8-container .swiper-container {
        padding-top: 80px;
    }

    .img8-container .swiper-slide {
        width: 28%;
    }

    .img6 {
        left: 20px;
        margin-top: -140px;
        height: 245px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 45%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 23px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        margin-top: 27%;
        margin-left: 10%;
        width: 84%;
    }

    .textarea {
        position: absolute;
        margin-top: 62%;
        width: 68%;
        height: 24%;
        /* UI Properties */
        border: 3px solid #F4FFC5;
        background-color: transparent;
        border-radius: 16px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 10px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 80%;
        margin-right: 48%;
        width: 50px;
        height: 15px;
        font: normal normal bold 9px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -20px;
    }

    .whatsapp {
        width: 70px;
        cursor: pointer;
    }
}

@media (max-width: 490px) {
    .img1 {
        width: 103%;
    }

    .button1 {
        gap: 8px;
        top: 180px;
        left: 110px;
        width: 90px;
        height: 30px;
        font-size: 10px;
    }

    .img8-container .swiper-container {
        padding-top: 20px;
    }

    .img8-container .swiper-slide {
        width: 45%;
        top: 60%;
    }

    .img6 {
        left: 20px;
        margin-top: -150px;
        height: 230px;
        /* UI Properties */
        background: #BBBBBB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 60px;
        opacity: 1;
    }

    .title {
        width: 50%;
        height: 114px;
        left: 25%;
        text-align: right;
        font: normal normal bold 23px/54px Almarai;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        position: absolute;
    }

    .text-input {
        display: flex;
        flex-direction: column;
        width: 65%;
        margin-top: 27%;
        gap: 5%;
    }

    .textarea {
        position: absolute;
        margin-top: 66%;
        width: 68%;
        height: 24%;
        /* UI Properties */
        border: 3px solid #F4FFC5;
        background-color: transparent;
        border-radius: 12px;
        opacity: 1;
        text-align: right;
        font-family: Almarai;
        font-size: 10px;
        letter-spacing: 0px;
        color: #001B0B;
        padding-right: 10px;
        padding-top: 5px;
        outline: none;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: 86%;
        margin-right: 46%;
        width: 50px;
        height: 15px;
        font: normal normal bold 9px Almarai;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 55px;
        opacity: 1;
        cursor: pointer;
    }

    .whatsapp-container {
        right: -16px;
    }

    .whatsapp {
        width: 150px;
        cursor: pointer;
    }
}